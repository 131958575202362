import { Option } from '~/components/common/Dropdown';

export const GRPC_NODE_ENDPOINT = process.env.NEXT_PUBLIC_GRPC_NODE_ENDPOINT || '';
export const GRPC_API_ENDPOINT = process.env.NEXT_PUBLIC_GRPC_API_ENDPOINT || '';
export const CLOUDINARY_API_KEY = process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY || '';
export const CLOUDINARY_CLOUD_NAME = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME || '';
export const WS_RELAYER_ENDPOINT = process.env.NEXT_PUBLIC_WS_RELAYER_ENDPOINT || '';

export const IS_LOCAL_ENV = process.env.NODE_ENV === 'development';
export const IS_DEV_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development';
export const IS_IG_PREVIEW_ENV =
  !IS_LOCAL_ENV &&
  !['https://dev-api.internet.game', 'https://api.internet.game'].includes(GRPC_API_ENDPOINT);
export const IS_VERCEL_PREVIEW_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
export const IS_STAGING_ENV = process.env.NEXT_PUBLIC_STAGE === 'staging';
export const IS_DEV_OR_PREVIEW_ENV = (IS_DEV_ENV || IS_VERCEL_PREVIEW_ENV) && !IS_STAGING_ENV;
export const IS_PRODUCTION_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

export const availableRegions: Array<Option> = [
  { label: 'US - East', value: 'us-east' },
  { label: 'US - West', value: 'us-west' },
  { label: 'Europe - North', value: 'eu-north' },
  { label: 'Europe - West', value: 'eu-west' },
  { label: 'Asia - South East', value: 'sea' },
  { label: 'Asia - East', value: 'ea' },
  { label: 'Asia-Pacific - North', value: 'ap-north' },
  { label: 'Asia-Pacific - South', value: 'ap-south' },
  { label: 'Asia-Pacific - South East', value: 'ap-southeast' },
];

export const availableHathoraRegions: Array<Option> = [
  { label: 'Chicago', value: 'Chicago' },
  { label: 'Seattle', value: 'Seattle' },
  { label: 'Los Angeles', value: 'Los_Angeles' },
  { label: 'Washington DC', value: 'Washington_DC' },
  { label: 'Sao Paulo', value: 'Sao_Paulo' },
  { label: 'London', value: 'London' },
  { label: 'Frankfurt', value: 'Frankfurt' },
  { label: 'Mumbai', value: 'Mumbai' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Tokyo', value: 'Tokyo' },
  { label: 'Sydney', value: 'Sydney' },
];

export const COMPANY_NAME = 'Internet Game';

export const SHOW_GAME_INSTRUCTIONS_DELAY = 1250;

export const DEFAULT_GAME = {
  value: 'ig-game-wordfind',
  label: 'Word Hunt',
};

export const ROOM_NAME_MAX_LENGTH = 24;

// ---------------------------------------------------------- LOCAL STORAGE KEYS
export const LOCAL_STORAGE_KEYS = {
  // Voice Chat
  IS_GAME_MUTED: 'IS_GAME_MUTED',
  IS_VOICE_CHAT_MUTED: 'IS_VOICE_CHAT_MUTED',
  IS_VOICE_CHAT_DEAFENED: 'IS_VOICE_CHAT_DEAFENED',
  VOICE_CHAT_PREFERED_INPUT_DEVICE: 'VOICE_CHAT_PREFERED_INPUT_DEVICE',
  VOICE_CHAT_PREFERED_OUTPUT_DEVICE: 'VOICE_CHAT_PREFERED_OUTPUT_DEVICE',
  AUTO_CONNECT_VOICE_CHAT: 'AUTO_CONNECT_VOICE_CHAT',
  HOVERED_VOICE_CHAT_PROMPT: 'HOVERED_VOICE_CHAT_PROMPT',

  // Pre Checks
  MACHINE_FULLFILLS_REQUIREMENTS: 'MACHINE_FULLFILLS_REQUIREMENTS',

  // Analytics
  SIGN_IN_TIME: 'SIGN_IN_TIME',
  SIGN_IN_START_TIME: 'SIGN_IN_START_TIME',

  // game server
  WS_RELAYER_ENABLED: 'WS_RELAYER_ENABLED',
};

// ---------------------------------------------------------- COOKIES
export const COOKIE_KEYS = {
  AUTO_JOIN_COMMUNITY: 'auto-join-community',
  IS_WEBSOCKET_PREFERRED_CONNECTION_METHOD: 'is-websocket-preferred-connection-method',
  NOT_A_CRAWLER: 'not-a-crawler', // used to disable functionality meant for crawlers in the middleware
};

export type AUTO_JOIN_COMMUNITY = {
  id: string;
  name: string;
  slug: string;
  activeSeats: number;
  inviteMethod: string;
};

// ---------------------------------------------------------- BILLING
export const MAX_FREE_USERS = 5;

// ---------------------------------------------------------- COLOR MANAGEMENT
export const COLORS = {
  PRIMARY: '#5168DE',
  ACCENT: '#5EFCD6',
  BUTTON_PRIMARY: '#5EFCD6',
  BUTTON_SECONDARY: '#1B1A53',
};

// ---------------------------------------------------------- Voice Chat
export const VOICE_CHAT_DISCONNECTED_EVENT = 'voice-chat-disconnected';

// ---------------------------------------------------------- BROWSER
export const SupportedBrowsers = ['Chrome', 'Firefox', 'Edge', 'Opera', 'Electron'];
